#contact {
    width: 100%;
    background-color: rgb(24, 23, 23);
    position: relative;
    padding-bottom: 30px;
    max-width: 1030px;
    margin: auto;
}
#contact h1{
    padding-top: 50px;
    padding-bottom: 50px;
    color: #ffffff;
    text-align: center;
}
#contact .contact{
    width: 85%;
    margin: auto
}

#contact h4{
    color:dimgray;
    margin-bottom: 10px;
}

#contact h6{
    color: grey;
    margin-bottom: 10px;
}
