#gallery {
    margin-bottom: 10px;
    height: 500px;
    max-width: 1030px;
    margin: 0 auto;
}

#gallery .carousel-inner{
    background-color: grey;
}



@media (max-width:576px){
    #gallery .carousel-item{
        height: 500px;
        position: relative;
    }
    #gallery img{
        width: 100%;    
        position:absolute;
        top: 50%;
        transform: translateY(-50%);

    }

    
}

@media (min-width:577px){
    #gallery img{
        width: auto;
        display: block;
        margin: auto;
        max-height: 500px;    
    }
}