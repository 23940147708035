#visa {
    width: 100%;
    margin: 0 auto;
    padding: 50px 5%;
    float: none;
    background-color: #fff;

}

#visa .title{
    color: #2d5c88
}
#visa p {
    margin-top: 30px;
    white-space: pre-line;
    color: #666666
}

@media only screen and (min-width:768px){
    #visa {
        max-width: 1030px;
    }
}