#carousel {
    max-height: 700px;
    max-width: 1030px;
    margin: auto;
}

#carousel .carousel-caption{
    top: 30%;
}
#carousel h3{
    color: rgb(250, 240, 240);
    font-size: 5vw;
    margin-bottom: 5vw;
    font-weight: 600;
    -webkit-text-stroke: 1px black;
}
#carousel p{
    color: rgb(238, 235, 221);
    font-size: 3vw;
    font-weight: 600;
    -webkit-text-stroke: 1px black;
}

@media (max-width: 576px) {
    #carousel h3{
        color: rgb(250, 240, 240);
        font-size: 26px;
        margin-bottom: 5vw;
        font-weight: 600;
        -webkit-text-stroke: 1px black;
    }
    #carousel p{
        color: rgb(238, 235, 221);
        font-size: 22px;
        font-weight: 600;
        -webkit-text-stroke: 1px black;
    }
}